<template>
  <div aria-labelledby="add plan page" ref="addPlanPage">
    <div>
      <div class="header-text pb-3" data-cy="header-fieldworks">Plan</div>
      <div class="subhead-text pb-5">
        Provide details of your meeting with your mentor to review the draft of
        your personal and professional development plan.
      </div>
    </div>
    <div class="grid grid-cols-4 grid-rows-3 gap-6">
      <div class="col-span-3 row-span-2">
        <div class="box-style">
          <form @submit.prevent="submitExperience">
            <div class="thirdhead-text pb-2">Date:</div>
            <div class="pb-3">Enter the date you met with your mentor.</div>
            <input type="date" class="rounded" v-model="form_input.date" />
            <!-- TODO: Limit dates to current academic year -->
            <div class="profile_box">
              <div class="thirdhead-text pb-2">Time:</div>
              <div class="pb-2">Enter the time spent on this experience</div>
              <div class="pb-3 flex gap-4">
                <div class="flex-none pt-4 font-bold w-16">Preparation:</div>
                <div class="px-5 flex-grow">
                  <input
                    type="text"
                    class="rounded text-base w-16"
                    placeholder="0.00"
                    v-model="form_input.time.preparation"
                  />
                </div>
              </div>
              <div class="pb-3 flex gap-4">
                <div class="flex-none pt-4 font-bold w-16">Travel:</div>
                <div class="px-5 flex-grow">
                  <input
                    type="text"
                    class="rounded text-base w-16"
                    placeholder="0.00"
                    v-model="form_input.time.travel"
                  />
                </div>
              </div>
              <div class="pb-3 flex gap-4">
                <div class="flex-none pt-4 font-bold w-16">Event:</div>
                <div class="px-5 flex-grow">
                  <input
                    type="text"
                    class="rounded text-base w-16"
                    placeholder="0.00"
                    v-model="form_input.time.event"
                  />
                </div>
              </div>
              <div class="flex gap-4 border-t w-1/4 border-steel-gray">
                <div class="flex-none pt-4 font-bold w-16">Total:</div>
                <div class="flex-grow pt-4 px-8 w-16">
                  {{
                    +form_input.time.event +
                    +form_input.time.travel +
                    +form_input.time.preparation
                  }}
                </div>
              </div>
            </div>

            <div class="profile_box">
              <div class="thirdhead-text pb-2">Description:</div>
              <div class="pb-2">
                Enter a brief description of your meeting with your mentor.
              </div>
              <div class="">
                <textarea
                  class="w-3/4 text-base bg-white bg-clip-padding border border-steel-gray rounded"
                  rows="3"
                  placeholder="Add your comments here."
                  v-model="form_input.description"
                ></textarea>
                <div class="w-3/4 text-right">
                  {{ form_input.description.trim().split(" ").length - 1 }} of
                  100 words
                </div>
              </div>
            </div>
            <div>
              <div class="flex justify-end">
                <button class="px-6 py-1 mr-3 rounded-full" @click="goBack">
                  Cancel
                </button>
                <input
                  type="submit"
                  value="Submit"
                  class="px-6 py-1 mr-5 border border-purple bg-purple hover:bg-dark-purple text-white rounded-full"
                  @click.prevent="$router.push('/fieldworks/add/success')"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-start-4 row-start-1">
        <!--
        <div class="box-style bg-light-gray">
          <div class="thirdhead-text pb-2">How to make accurate entries:</div>
          <ol class="text-xl p-3 list-decimal">
            <li class="pb-1 text-dark-purple">
              <div class="inline-block">
                <span class="text-dark-gray">Pick the right category</span>
              </div>
            </li>
            <li class="pb-1 text-dark-purple">
              <div class="inline-block">
                <span class="text-dark-gray">Write detailed descriptions</span>
              </div>
            </li>
            <li class="pb-4 text-dark-purple">
              <div class="">
                <span class="text-dark-gray"
                  >Include personal reflections or observations</span
                >
              </div>
            </li>
          </ol>
        </div>
-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddExperience",
  components: {},
  mounted() {
    this.$refs.addPlanPage.focus(); 
  },
  data() {
    return {
      form_input: {
        date: "",
        time: {
          preparation: "",
          travel: "",
          event: "",
        },
        description: "",
      },

      lookup_selection: [],
      show_modal: false,
      lookup_categories: {
        category_1: {
          title: "Administrative Law",
          index: 1,
          open: false,
          items: {
            option_one: {
              title:
                "Attend a contested case before an administrative law judge",
              value: null,
            },
            option_two: {
              title:
                "Attend a formal adjudication before an administrative law judge",
              value: null,
            },
            option_three: {
              title: "Attend a meeting regarding a proposed rule change",
              value: null,
            },
            option_four: {
              title:
                "Attend a rulemaking hearing before an administrative law judge",
              value: null,
            },
            option_five: {
              title:
                "Attend an administrative hearing before a commissioner's representative",
              value: null,
            },
          },
        },
        category_2: {
          title: "Alternative Dispute Resolution",
          index: 2,
          open: false,
          items: {
            option_one: {
              title: "Attend a mediation",
              value: null,
            },
            option_two: {
              title: "Attend an arbitration",
              value: null,
            },
            option_three: {
              title:
                "Draft a confidential memo or letter to a mediator in preparation for a mediation",
              value: null,
            },
            option_four: {
              title:
                "Review or compile evidentiary documents for an arbitration or mediation",
              value: null,
            },
            option_five: {
              title:
                "Review or draft non-evidentiary materials for arbitration or mediation",
              value: null,
            },
          },
        },
        category_3: {
          title: "Banking Law",
          index: 3,
          open: false,
          items: {
            option_one: {
              title: "Attend a tour of the Federal Reserve",
              value: null,
            },
            option_two: {
              title: "Draft or review a credit agreement",
              value: null,
            },
            option_three: {
              title: "Draft or review a credit summary",
              value: null,
            },
            option_four: {
              title:
                "Draft or review an opinion or pre-authorization from the Federal Reserve",
              value: null,
            },
            option_five: {
              title: "Review the risk examination process of the FDIC",
              value: null,
            },
          },
        },
        category_4: {
          title: "Antitrust Law",
          index: 4,
          open: false,
          items: {
            option_one: {
              title: "Attend an antitrust training of a group of employees",
              value: null,
            },
            option_two: {
              title: "Review a NCRPA notification",
              value: null,
            },
            option_three: {
              title: "Review a proposed merger for antitrust issues",
              value: null,
            },
            option_four: {
              title: "Review antitrust compliance program documentation ",
              value: null,
            },
            option_five: {
              title: "Attend a hearing before an antitrust tribunal",
              value: null,
            },
          },
        },
        category_5: {
          title: "Criminal Law",
          index: 5,
          open: false,
          items: {
            option_one: {
              title: "Attend a ride along with a police officer",
              value: null,
            },
            option_two: {
              title: "Attend a DWI proceeding",
              value: null,
            },
            option_three: {
              title: "Attend a criminal motion hearing",
              value: null,
            },
            option_four: {
              title:
                "Attend a criminal pre-trial, omnibus hearing or probable cause hearing, contested or uncontested",
              value: null,
            },
            option_five: {
              title: "Attend a mental health court proceeding",
              value: null,
            },
          },
        },
      },
    };
  },
  methods: {
    submitExperience: function () {
      this.form_input = {
        date: "",
        time: {
          preparation: "",
          travel: "",
          event: "",
        },
        description: "",
        name: "",
      };
      this.$router.push("/fieldworks/add/success");
    },
    goBack: function () {
      this.$router.go(-1);
    },
    showSelection: function (selection) {
      // TODO: We should be using form validation to make sure only one selection is passed
      this.form_input.name = `${selection[0].title}`;
      this.show_modal = !this.show_modal;
    },
  },
};
</script>

<style scoped></style>
